<template>
  <svg
    id="Capa_1"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 512 512"
    height="20"
    viewBox="0 0 512 512"
  >
    <path
      d="m497 422c-8.284 0-15 6.716-15 15v45h-45c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15z"
    />
    <path
      d="m75 482h-45v-45c0-8.284-6.716-15-15-15s-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
    />
    <path
      d="m497 0h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h45v45c0 8.284 6.716 15 15 15s15-6.716 15-15v-60c0-8.284-6.716-15-15-15z"
    />
    <path
      d="m15 90c8.284 0 15-6.716 15-15v-45h45c8.284 0 15-6.716 15-15s-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15z"
    />
    <path
      d="m434.877 184.066c-59.533-40.745-121.342-62.552-178.744-63.061-.089-.002-.178-.002-.267 0-57.402.509-119.211 22.315-178.744 63.061-44.552 30.494-72.076 60.582-73.226 61.849-5.195 5.72-5.195 14.45 0 20.17 1.15 1.267 28.673 31.355 73.226 61.849 59.533 40.745 121.342 62.552 178.744 63.061.044.001.089.001.133.001s.089 0 .133-.001c57.402-.509 119.211-22.315 178.744-63.061 44.553-30.493 72.076-60.582 73.226-61.849 5.195-5.72 5.195-14.45 0-20.17-1.148-1.267-28.672-31.355-73.225-61.849zm-178.877 176.934c-57.897 0-105-47.103-105-105s47.103-105 105-105 105 47.103 105 105-47.103 105-105 105zm-219.913-105c11.353-10.957 31.83-29.344 58.587-47.591 14.142-9.645 31.193-19.99 50.362-29.207-15.148 21.814-24.036 48.286-24.036 76.798 0 28.519 8.9 54.992 24.053 76.812-19.177-9.222-36.232-19.573-50.379-29.221-26.76-18.248-47.237-36.636-58.587-47.591zm381.239 47.591c-14.151 9.65-31.211 20.005-50.393 29.229 15.159-21.821 24.067-48.295 24.067-76.82 0-28.512-8.887-54.985-24.035-76.8 19.169 9.218 36.218 19.564 50.361 29.209 26.76 18.248 47.237 36.636 58.587 47.591-11.352 10.957-31.83 29.344-58.587 47.591z"
    />
    <path
      d="m256 191c-35.841 0-65 29.159-65 65s29.159 65 65 65 65-29.159 65-65-29.159-65-65-65zm0 100c-19.299 0-35-15.701-35-35s15.701-35 35-35 35 15.701 35 35-15.701 35-35 35z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'EyeIcon'
  }
</script>
