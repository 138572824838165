<template>
  <FormValidatedContainer
    :name="name"
    :class="[{ 'pass-container': type === 'password' }, $attrs.class]"
    :title="title"
    :show-title="showTitle"
    :errors="errors"
    :disabled="disabled"
    :required="required"
    :tooltip="tooltip"
    :tooltip-text="tooltipText"
    :input-style="inputStyle"
  >
    <template #default="{ hasError }">
      <input
        :id="name"
        v-maska
        :data-maska="mask"
        :class="[
          inputClasses,
          { 'border-pink': hasError, cursor_disabled: !!disabled },
          { 's-registration-input-float': inputStyle === 'float' },
          { '[&~label]:!-top-0': modelValue !== '' }
        ]"
        :type="theType"
        :value="modelValue"
        :placeholder="inputStyle !== 'float' ? placeholder : ''"
        :disabled="disabled"
        :autocomplete="autocomplete"
        data-lpignore="true"
        class="peer"
        :name="name"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <IconEye
        v-if="type === 'password'"
        class="-top-9 mr-3 h-6 w-6 relative float-right cursor-pointer"
        :class="[{ 'show-password': showPassword }]"
        @click="eyeClicked"
      />
    </template>
  </FormValidatedContainer>
</template>

<script>
  import { vMaska } from 'maska'
  import { radarAutoCompleteHandler } from '@/utils/radarAutocompleteHandler'

  export default {
    name: 'ValidatedInput',
    directives: { maska: vMaska },
    inheritAttrs: false,
    props: {
      inputClasses: {
        type: String,
        required: false,
        default: 'block s-registration-input'
      },
      /**
       * The name of this component. Equivalent to name field on input
       */
      name: {
        type: String,
        required: true
      },

      /**
       * The data field that gets 2 way binded with v-model
       */
      modelValue: {
        type: String,
        default: ''
      },

      /** The title that will be rendered on the screen. If null title will be determined from name field */
      title: {
        type: String,
        default: null
      },

      /** Whether the title should be shown. */
      showTitle: {
        type: Boolean,
        default: true
      },

      /**
       * Equivalent to the type field on an input tag
       */
      type: {
        type: String,
        default: 'text'
      },

      /**
       * The placeholder string that will appear when the user has not entered a value
       */
      placeholder: {
        type: String,
        default: ''
      },

      tooltip: {
        type: Boolean,
        default: false
      },

      tooltipText: {
        type: String,
        default: ''
      },

      /**
       * A list of validation errors. If a key in this object equals the :name prop of this component then the message
       * in that key will be rendered
       */
      errors: {
        type: Object,
        default() {
          return {}
        }
      },

      /**
       * Allows you to make input masks for things like phone numbers.
       * Example  mask=(###) ###-#### will force input to look like (123) 456-1234 and will only allow numbers
       */
      mask: {
        type: String,
        default: null
      },

      /**
       * If true this field will be disabled
       */
      disabled: {
        type: Boolean,
        default: false
      },

      required: {
        type: Boolean,
        default: false
      },

      autocomplete: {
        type: String,
        default: 'off'
      },
      /**
       * Style of the input (Primary / else: Float Placeholder)
       */
      inputStyle: {
        type: String,
        default: 'float'
      }
    },
    emits: ['update:modelValue'],

    data() {
      return {
        showPassword: false
      }
    },

    computed: {
      /**
       * Determines the 'type' of the input tag. normally just uses the :type prop but if type=password we need to be
       * able to show/hide the password text when you click the eye
       */
      theType() {
        if (this.type === 'password') {
          if (this.showPassword) {
            return 'text'
          }
        }

        return this.type
      }
    },

    methods: {
      eyeClicked() {
        this.showPassword = !this.showPassword
      }
    }
  }
</script>

<style lang="postcss">
  .cursor_disabled {
    cursor: not-allowed;
  }

  .s-registration-input-float {
    min-block-size: 3rem;
    @apply !pb-0;
  }

  .s-registration-input {
    border: solid #e5eaed 2px;
    @apply font-alt;
    color: #002d52;
    min-width: 100%;

    @apply p-3 text-sm;
  }

  .s-registration-input:focus {
    outline: none;
  }

  .validation-error {
    border: solid #e5eaed 2px;

    input {
      border-color: #fe84b8;
      background: rgba(243, 182, 208, 0.3);
    }
  }

  .s-error-message {
    color: #fe84b8;
  }

  .s-registration-password-svg {
    color: #e5eaed;
    float: right;
    right: 15px;
    top: 37px;
    cursor: pointer;
  }

  .show-password {
    color: #fe84b8;
  }
</style>
